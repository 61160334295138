<template>
  <div>
    <v-card-text>
      <v-alert v-if="!documentReady" text type="info">No hay data para mostrar</v-alert>
      <v-card v-else outlined class="white pa-2">
        <iframe
          id="frameID"
          style="border: none"
          width="100%"
          :height="window.height"
          :src="urlReportViewer"
        ></iframe>
      </v-card>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-form ref="form">
          <v-select
            :items="listaSistemas"
            v-model="selectedSistema"
            :rules="sistemaValidation"
            item-text="Nombre"
            item-value="Nombre"
            placeholder="Seleccione sistema"
            :disabled="processing"
            hint="Filtro por sistema"
            append-icon="keyboard_arrow_down"
            persistent-hint
          ></v-select>
          <v-select
            :items="listaReporte"
            v-model="selectedReport"
            :rules="reporteValidation"
            item-text="Nombre"
            item-value="ReporteCode"
            placeholder="Seleccione reporte"
            :disabled="processing"
            hint="Filtro por reporte"
            append-icon="keyboard_arrow_down"
            persistent-hint
          ></v-select>

          <v-select
            v-if="
              selectedSistema !== 'Fac' &&
              selectedReport !== 'ReporteEstadoCuentaMultiple'
            "
            :items="listaCortes"
            :rules="corteValidation"
            v-model="selectedCorte"
            item-text="fechas"
            item-value="CorteId"
            placeholder="Seleccione corte"
            :disabled="processing"
            hint="Filtro por corte"
            append-icon="keyboard_arrow_down"
            persistent-hint
          ></v-select>

          <v-select
            multiple
            v-if="
              selectedSistema !== 'Fac' &&
              selectedReport === 'ReporteEstadoCuentaMultiple'
            "
            :items="listaCortes"
            :rules="corteValidation"
            v-model="selectedCorteMultiple"
            item-text="fechas"
            item-value="CorteId"
            placeholder="Seleccione corte"
            :disabled="processing"
            hint="Filtro por corte"
            append-icon="keyboard_arrow_down"
            persistent-hint
          ></v-select>
        </v-form>
        <br />
        <div class="mb-5 text-right">
          <v-btn block class="ma-1" depressed color="primary" @click="generate">
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiReporte from "@/api/reportes";
import { mapActions } from "vuex";
import { global } from "@/api/global";

let _baseurl = global.baseWebUrl + "/Pages/ReportingViewer.aspx?";

export default {
  data: () => {
    return {
      drawerRight: true,
      selectedCorte: null,
      selectedCorteMultiple: null,
      selectedReport: null,
      selectedSistema: "Indac",
      documentReady: true,
      listaSistemas: [],
      listaReporte: [],
      processing: false,
      urlReportViewer: global.baseWebUrl + "loader.html",
      corteValidation: [(v) => !!v || "Debe seleccionar un corte"],
      reporteValidation: [(v) => !!v || "Debe seleccionar un reporte"],
      sistemaValidation: [(v) => !!v || "Debe seleccionar un sistema"],
      window: {
        width: 0,
        height: 0,
      },
      breadcrumb: [
        {
          text: "Reportes",
          disabled: true,
        },
        {
          text: "Comisiones",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    listaCortes() {
      //filtra cortes completados para el selector de reportes
      return this.$store.getters.cortes.filter((c) => c.BCompletado);
    },
  },
  watch: {
    selectedReport: function () {},

    selectedSistema: function () {
      this.getReportes();
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    getReportes() {
      if (this.$store.getters.user.canalid) {
        apiReporte
          .GetReportListByCanalID(this.$store.getters.user.canalid, this.selectedSistema)
          .then((response) => {
            this.listaReporte = response.data;
            this.documentReady = false;
          })
          .catch((error) => {
            this.setError(error);
            this.documentReady = false;
          });
      } else {
        this.setMessage("Favor seleccionar un Asociado primero");
      }
    },
    getSistemas() {
      apiReporte
        .GetSistemas()
        .then((response) => {
          this.listaSistemas = response.data;
          this.documentReady = false;
        })
        .catch((error) => {
          this.setError(error);
          this.documentReady = false;
        });
    },
    generateFacReport() {
      var self = this;

      setTimeout(function () {
        self.processing = false;
        self.urlReportViewer =
          _baseurl +
          "ReporteFacViewer?" +
          "reportName=" +
          self.selectedReport +
          "&socioId=" +
          self.$store.getters.user.asociado.socioId;
      }, 2000);
    },
    generateIndacReport() {
      var self = this;

      setTimeout(function () {
        self.processing = false;
        self.urlReportViewer =
          _baseurl +
          "reportName=" +
          self.selectedReport +
          "&corteId=" +
          self.selectedCorte +
          "&canalAsociadoId=" +
          self.$store.getters.user.asociado.canalAsociadoId;
      }, 2000);
    },
    generate() {
      if (this.$refs.form.validate()) {
        this.documentReady = true;
        this.processing = true;

        if (this.selectedSistema === "Fac") {
          this.generateFacReport();
        } else {
          this.generateIndacReport();
        }
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 200;
    },
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    this.getSistemas();
    this.getReportes();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
